<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>用戶列表</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <v-client-table :data="users" :columns="tableConfigs.columns" :options="tableConfigs.options">
          <template slot="nickname" slot-scope="props">
            <el-avatar :src="props.row.headimgurl"></el-avatar>
            {{ props.row.nickname }}
          </template>

          <template slot="location" slot-scope="props">
            {{ props.row.country }}
            {{ props.row.province }}
            {{ props.row.city }}
          </template>

        </v-client-table>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'Users',
  mounted() {
    this.fetchUsers();
  },
  data() {
    return {
      users: [],
      tableConfigs: {
        columns: [
          'nickname', 'sex', 'email', 'phone', 'location', 'created_at',
          // 'operation'
        ],
        options: {
          headings: {
            nickname: '暱稱',
            sex: '性別',
            email: '郵箱',
            phone: '手機號',
            location: '地區',
            created_at: '註冊時間',
            operation: '操作',
          },
          filterable: true,
          sortable: [
            'created_at',
          ],
          orderBy: {
            ascending: false,
            column: 'created_at',
          },
          // descOrderColumns: [
          //   'created_at',
          // ],
          perPage: 50,
          perPageValues: [50, 100, 200, 300],
        },
      },
    };
  },
  computed: {},
  methods: {
    fetchUsers() {
      const loading = getLoadingInstance();
      this.$api.user.get().then(({ data: response }) => {
        this.users = [...response.data];
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss">

</style>
